import React from 'react';

function Insumos() {
  return (
    <div>
      <h2>Insumos</h2>
      <p>Aquí encontrarás información sobre nuestros insumos.</p>
    </div>
  );
}

export default Insumos;
