import React from 'react';

function About() {
  return (
    <div>
      <h1>About Page</h1>
      <p>Welcome to the about page!</p>
    </div>
  );
}

export default About;
