import React from 'react';

function Cursos() {
  return (
    <div>
      <h2>Cursos</h2>
      <p>Aquí encontrarás información sobre nuestros cursos.</p>
    </div>
  );
}

export default Cursos;
